<template>
  <div>
    <v-toolbar flat color="white">
      <v-toolbar-title class="dodo">ألمجلات</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
 


     
       <v-spacer></v-spacer>
      <v-dialog v-model="dialog"  persistent max-width="770px">
        <v-btn slot="activator" color="primary" @click="dialog=true" dark class="dodo mb-2" >عدد جديد</v-btn>

        <v-form v-model="valid" ref="form">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>




          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                  
            <v-flex xs12 sm6 md12>
              <v-text-field 
              v-model="editedItem.num" 
              class="form_label"
              required                    
              :rules="numRules"
               label="رقم العدد"
                v-validate="'required'"
               ></v-text-field>
            </v-flex>

              <v-flex xs12 sm6 md12>
              <v-text-field 
              v-model="editedItem.jurn_open_title" 
              class="form_label" label="عنوان الافتتاحيه"></v-text-field>
            </v-flex>


                <v-flex xs5 lg6>

                  <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="editedItem.created_at"
            label="تاريخ النشر"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(editedItem.created_at)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>


       
      
        </v-flex>


              <v-flex xs5 sm5 md5>
              <v-text-field 
              v-model="editedItem.date_hjry" 
              class="form_label"
              required                    
          
               label="التاريخ الهجري"
          
               ></v-text-field>
            </v-flex>





                

          <v-flex xs12 sm12 md12>
              <v-textarea                 
              v-model="editedItem.jurn_open_details"
              autofocus
              browser-autocomplete
              label="أفتتاحيه العدد"
             
              > 
              </v-textarea>
          </v-flex>



                <v-flex xs6 >
                  <v-text-field label="أختار صوره العدد" @click='pickFile' v-model='editedItem.imageName' prepend-icon='fa-solid fa-paperclip'></v-text-field>
                  <input
                    type="file"
                    style="display: none"
                    ref="image"
                    accept="image/*"
                    @change="onFilePicked"
                  >
                </v-flex>

                  <v-flex xs6 >
                  <img :src="Url+imageUrl" height="150" v-if="imageUrl"/>
                </v-flex>



                <v-flex xs6 >
                  <v-text-field label=" PDF أختار ملف العدد " @click='pickPDF' v-model='pdfName' prepend-icon='fa-solid fa-paperclip'></v-text-field>
                  <input
                    type="file"
                    style="display: none"
                    ref="pdf"
                    accept="pdf/*"
                    @change="onFilePickedPDF"
                  >

                </v-flex>

 <v-flex xs6>
                 
                </v-flex>


                 <v-flex xs6>
                  <v-text-field label=" ZIP أختار ملف العدد  " @click='pickZip' v-model='ZIPName' prepend-icon='fa-solid fa-paperclip'></v-text-field>
                  <input
                    type="file"
                    style="display: none"
                    ref="zip"
                    accept="image/*"
                    @change="onFilePickedZIP"
                  >
                </v-flex>

                  

            
              </v-layout>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="close">Cancel</v-btn>
            <v-btn color="blue darken-1" :loading="loadSave" flat @click="save">Save</v-btn>
          </v-card-actions>
        </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
    <v-data-table
    hide-default-footer
  disable-pagination
      :headers="headers"
      :items="journals"
      class="elevation-1"
      total-items="15"
    >
      <template slot="items" slot-scope="props">
        <td>{{ props.item.num }}</td>
        <td class="text-xs-right">{{ props.item.views}}</td>
        <td class="text-xs-right">{{ props.item.downloads }}</td>
        <td class="text-xs-right">{{ props.item.name }}</td>
         <td class="text-xs-right">{{ props.item.created_at }}</td>
        
         
       
       
      
      </template>
      <template slot="no-data">
        <v-btn color="primary" @click="initialize">Reset</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
     
     <v-icon
         small
         class="mr-2"
         @click="editItem(item)"
       >
       تعديل
       </v-icon>
       |
       <v-icon
         small
         @click="deleteItem(item)"
       >
         حذف
       </v-icon>
       


</template>
    </v-data-table>

    <v-pagination class="pagination" total-visible="20" v-model="page"
      color="#c7000b" style="position: relative; top: 20px;" circle=""
      :length="pageCount">
    </v-pagination>
  </div>
</template>


<script>
 import axios from 'axios';
 import swal from 'sweetalert2';
  export default {
    data: vm => ( {
     
     
      numRules: [
          (v) => !!v || 'يجب ادخال رقم العدد',
         
        ],
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
 
    created_at:vm.formatDate(new Date().toISOString().substr(0, 10)),
      attachments:[],
      

    mails_status:[],
    loding:false,
    loadSave:false,
      pdfName:'',
      created_at:'',
      form: new FormData,  
      dialog: false,
      imagesUrl:'',
      ZIPName:'',
      page: 1,
        pageCount: 0,
        current_page: 1,
        last_page: 0,
      imageName: '',
      img_old:'',
      imagesName:' ',
        search:null,
        filez:[],
      imageFile:[],
		imageUrl: '',
		imageFile: '',
       dropdown_icon: [
        { text: 'list', callback: () => console.log('list') },
        { text: 'favorite', callback: () => console.log('favorite') },
        { text: 'delete', callback: () => console.log('delete') }
      ],
      arts:[],
      journals:[],
      cats:[],
      headers: [
        {
          text: 'رقم العدد',
          align: 'left',
          value: 'num'
        , sortable: false
        },
        { text: 'عدد المشاهدات', value: 'views', sortable: false },
        { text: 'عدد التحميلات', value: 'downloads' , sortable: false},
        { text: 'ألناشر', value: 'name', sortable: false },
        { text: 'تاريخ النشر', value: 'created_at', sortable: false },
        
     
        { text: 'Actions', value: 'actions', sortable: false }
      ],
       data:'',
       valid: false,
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: '',
        email:'',
        phone: '',
        password: '',
     
     
      },
        menu1: false,
      defaultItem: {
        name: '',
        email:'',
        phone:'',
        password:'',
       
      },
       def: {
        name: 'sds',
    
       
      }

    }),


    computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'أضافه كاتب جديد' : 'تعديل معلومات كاتب'
            },selected: function () {
        return this.getMoreitems();
      }
        },

          watch: {

      selected: 'search by sub_cat_id',

    },

    
    computed: {

       computedDateFormatted () {
      return this.formatDate(this.date)
    },

      formTitle () {
        return this.editedIndex === -1 ? 'أضافه عدد جديد' : 'Edit Item'
      }


      ,selected: function () {
  return this.getMoreitems();
}
    },

   
  
    watch: {

          
    date (val) {
      this.editedItem.created_at = this.formatDate(this.date)
    },
  
    selected: 'search by sub_cat_id',


      
    },
      dialog (val) {
        val || this.close()
      },
    

    created () {

       Fire.$on('cats_fliter',() => {
           
             axios.get('art_catid/'+this.editedItem.cat_filter_id).then(response => (this.arts = response.data)) 
            
        });
        this.mails_state()
       this.initialize()
       this.categories()

       
     
      
    },

    methods: {
      getMoreitems() {
        if (this.current_page <= this.last_page) {
          this.current_page = this.page;
          this.initialize();

        }
      },
       mails_state(){
           axios.get('ur_mails_status').then(response => (this.mails_status = response.data)) 
      },

        pickFile () {
           this.$refs.image.click ()
        },

        pickPDF () {
         this.$refs.pdf.click ()       
        },

        pickZip () {
          this.$refs.zip.click () 
        },
        


       onFilePickedPDF (e) {

            let pdf=e.target.files;
            this.pdf = pdf[0]
            this.pdfName = pdf[0].name
        },

         onFilePickedZIP (e) {

            let zip=e.target.files;
            this.zip= zip[0]
            this.ZIPName = zip[0].name
        },

		
	  	onFilePicked (e) {
      

      const files = e.target.files  
			if(files[0] !== undefined) {
      this.imageName = files[0].name
      

				if(this.imageName.lastIndexOf('.') <= 0) {
					return
        }
        
       
				const fr = new FileReader ()
				fr.readAsDataURL(files[0])
				fr.addEventListener('load', () => {
         
					this.imageUrl = fr.result
          this.imageFile = files[0] // this is an image file that can be sent to server...
console.log(this.imageFile);
         
  
    
				})
			} else {
        /*
				this.imageName = ''
				this.imageFile =''
        this.imageUrl = ''
        */
        
			}
    },


     formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${year}-${month}-${day}`
    },
    parseDate (date) {
      if (!date) return null

      const [ year,month,day ] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    
   

     cats_filter(){
     
       
        Fire.$emit('cats_fliter');
      },

      initialize () {  
        
        axios.get('journals?page=' + this.current_page,{
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorizations: "Bearer " + this.$store.state.AdminInfo.token
            }
          })

     
        
        .then(d => {
          this.journals = d.data.data
            this.last_page = d.data.last_page;
            this.pageCount = d.data.last_page;
          })


      
      },

      categories(){
           axios.get('cats').then(response => (this.cats = response.data)) 
      },

      

      editItem (item) {

       
    

       this.editedIndex = this.journals.indexOf(item)
       this.editedItem = Object.assign({}, item)
     
        this.dialog = true

            if(this.imageUrl[0]==null)
        {

            this.img_old= this.editedItem.photo;
            this.imageUrl='journals_imgs/main_thumb/'+this.img_old;
           
        }
      },

      deleteItem (item) {
       
        
        const index = this.desserts.indexOf(item)
       

        this.$swal({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                axios.delete('jur_deleted/'+item.id,  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(()=>{
                    this.$swal(
                                        'Deleted!',
                                        'Your file has been deleted.',
                                        'success'
                                        )
                                     this.initialize()
                                }).catch(()=> {
                                  this.$swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    })

                    

      },




       sendmail (item) {
       
        
        const index = this.desserts.indexOf(item)
       

        this.$swal({
                    title: 'أرسال العدد للمشتركين',
                    text: "هل انت متاكد من ارساله هاذا العدد للمشتركين.",
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                    }).then((result) => {
                        // Send request to the server
                         if (result.value) {
                                axios.post('jour_mails_sends/'+item.id,  {
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(()=>{
                    this.$swal(
                                        '',
                                        '',
                                        'success'
                                        )
                                     this.initialize()
                                }).catch(()=> {
                                  this.$swal("Failed!", "There was something wronge.", "warning");
                                });
                         }
                    })

                    

      },

      close () {
        this.dialog = false
        this.imageUrl='';
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        }, 300)
      },

      save () {

      


               if (this.editedIndex > -1) {

              
           if(this.$refs.form.validate())
          {
              if(this.pdf != null)
        {
              let formDataPdf = new FormData();
              formDataPdf.append('pdf', this.pdf);
              axios.post( 'uploude-pdf',
              formDataPdf,
              {
                headers: {'Content-Type': 'multipart/form-data', Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token}
              }
              )
        }

      if(this.zip != null)
        {
               // Uploude ZIP FILE
              let formDataZip = new FormData();
              formDataZip.append('zip',this.zip);
              axios.post( 'uploude-zip',
              formDataZip,
              {
                headers: {'Content-Type': 'multipart/form-data', Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token,}
              }
              )
        }


    
        if(this.imageFile != '')
        {

              let formData = new FormData();
              formData.append('photo', this.imageFile);
              axios.post( 'journals-uploude-img/'+this.editedItem.id,
              formData,
              {
                headers: {'Content-Type': 'multipart/form-data', Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token}
              }
              )

     
              
        }


       
               axios.post('jur_update/'+this.editedItem.id,{
                jurn_num:this.editedItem.num,
                jurn_open_details:this.editedItem.jurn_open_details,
                jurn_open_title:this.editedItem.jurn_open_title,
                date:this.editedItem.created_at ,
                date_hjry:this.editedItem.date_hjry,
               
					
            },{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  });
        this.initialize ();
        this.initialize ();
            
            this.close();
            
        }

      }
        
        else {

           
       
          this.loadSave=true;
            if(this.$refs.form.validate())
          {
        
                axios.post('journals', {
                jurn_num:this.editedItem.num,
                jurn_open_details:this.editedItem.jurn_open_details,
                jurn_open_title:this.editedItem.jurn_open_title,
                 date:this.editedItem.created_at ,
                 date_hjry:this.editedItem.date_hjry,
          
            
            },{
                    headers: {
                      "Content-Type": "application/json",
                      Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token
                    }
                  }).then(() => {


					   
              //  Fire.$emit('AfterCreate');
              //  toast({
              //     type: 'success',
              //     title: 'Signed in successfully'
              //  })


	 
             
             if(this.imageFile != null)
              {
           
             
              let formData = new FormData();
              formData.append('photo',this.imageFile);
              axios.post( 'journals-uploude-img/'+null,
              formData,
              {
                headers: {'Content-Type': 'multipart/form-data', Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token}
              },   
              ).then(() => {
                  console.log('successful')
            
              }).catch(() => { });




               }

              //Send PDF To Server
             
             if(this.pdf != null)
              {
              let formDataPdf = new FormData();
              formDataPdf.append('pdf', this.pdf);
              axios.post( 'uploude-pdf',
              formDataPdf,  
              {
                headers: {'Content-Type': 'multipart/form-data', Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token}
              }
              ).then(() => {
                this.dialog = false;
                this.loadSave=false;
                this.initialize();
            
              }).catch(() => { });
              }

              // Uploude ZIP FILE
              if(this.zip != null)
              {
              let formDataZip = new FormData();
              formDataZip.append('zip',this.zip);
              axios.post( 'uploude-zip',
              formDataZip,
              {
                headers: {'Content-Type': 'multipart/form-data', Accept: "application/json",
                      Authorizations: "Bearer " + this.$store.state.AdminInfo.token}
              }
              )
              }


            
           
              
				   }).catch(() => {});
           
        }
        this.$swal(
                                        '',
                                        '',
                                        'success'
                                        )
        this.initialize()
      }
      }
    },

          mounted () {
        this.$validator.localize('en', this.dictionary)
        
         }
         

  }
</script>